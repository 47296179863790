
 const siteURL = "https://testspapi.panelreward.com/api/";   // For Production
 const url = {
   participantUrl : "",
  //participantUrl: "https://testparticipant.panelreward.com/",
 }

 //const siteURL = "http://localhost:8089/api/";



const appConfig ={
  siteURL : siteURL,
  url : url
}

export default appConfig