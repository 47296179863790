
import {updateParticipantApi} from '../api/successApi'; 

export const updateParticipant = (data) => {
	return dispatch => {

		updateParticipantApi(data).then((result) => {

			if(result.newSurveyParticipant){

				dispatch(setparticipantRedirectUrl(result));
			}else{

				dispatch(setUpdateParticipant(result));
				if(result.message){
					alert(result.message);
				}	
			}

		});
	}
}

export const setUpdateParticipant = (updateParticipantResp) => {
	return {
		type: "SET_UPDATE_PARTICIPANT_RESPONSE",
		updateParticipantResp

	}
}

export const setparticipantRedirectUrl = (participantRedirectUrlRes) => {
	return {
		type: "SET_PARTICIPANT_REDIRECT_URL_RES",
		participantRedirectUrlRes
	}
}