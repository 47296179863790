import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateParticipant } from '../../actions/successAction';
import appConfig from '../../api/apiConfig'
import './style.css';

class StudySuccess extends Component {
  constructor(props) {
    super(props)
    this.queryString = window.location.search;

    if (this.queryString !== "") {

      let searchParams = getUrlParam('UID','Empty');
      let searchParams1 = getUrlParam('PID','Empty');
      //new URLSearchParams(window.location.href)
      if(searchParams && searchParams!=='Empty' && searchParams1 && searchParams1!=='Empty'){
        this.uid = searchParams;  //participant id
        this.sid = searchParams1;
      }else if(searchParams && searchParams!=='Empty'){
        this.uid = searchParams;  //participant id
        this.sid = "study";
      }

      // if (this.queryString !== "" && this.queryString.includes("UID") && this.queryString.includes("PID")) {
      // this.uid = this.queryString.split('UID=')[1].split('&')[0];  //participant id 
      // this.sid = this.queryString.split('PID=')[1];
      // }else if(this.queryString.includes("UID")){
      //   this.uid = this.queryString.split('UID=')[1]; //participant id 
      //   this.sid = 'study';
      // }
    }

    this.state = {
      uid: this.uid,
      sid: this.sid
    }

    this.updateParticipant = this.updateParticipant.bind(this);
  }

  componentWillMount() {
    if (this.queryString !== "") {
      let searchParams = getUrlParam('UID','Empty');
      if(searchParams && searchParams!=='Empty'){
        this.updateParticipant()
      }else{
        alert("You are requesting with bad parameters.");
      }
    }
  }

  componentWillReceiveProps(nextProps) {

    if(nextProps.participantNewStudyUrlSuccess){

      let sid = nextProps.participantNewRedirectUrlData.s_id;
      let tid = nextProps.participantNewRedirectUrlData.t_id;
      let uid = nextProps.participantNewRedirectUrlData.u_id;

      let newStudyUrl = appConfig.url.participantUrl + "?SID=" + sid + "&TID=" + tid + "&UID="+ uid;
      window.open(newStudyUrl, '_self');
  }
}

  updateParticipant() {
    let participantData = {};

    participantData.sid = this.state.sid;
    participantData.status = 1;
    participantData.uid = this.state.uid;
    participantData.checkLoi = true;

    this.props.dispatch(updateParticipant(participantData));
  }

  render() {
    let participant = this.props.updateParticipantResp;
    let url = "";

    if (participant) {
      if (participant.success && participant.updatedData && participant.updatedData.status === "1") {
        if(participant.mappingData.successUrl.includes("gowebsurveys.com") ){
          url = participant.mappingData.successUrl.split("<uid>")[0];
        }else if(participant.mappingData.successUrl.includes("<uid>") ){
          url = participant.mappingData.successUrl.split("<uid>")[0] + participant.updatedData.uid + participant.mappingData.successUrl.split("<uid>")[1];
        }else{
          url = participant.mappingData.successUrl;
        }
        window.open(url, '_self');
        
      } else if (participant.updatedData && participant.updatedData.status === "6") {

        if(participant.mappingData.terminateUrl.includes("gowebsurveys.com") ){
          url = participant.mappingData.terminateUrl.split("<uid>")[0];
        }else if(participant.mappingData.terminateUrl.includes("<uid>")){
          url = participant.mappingData.terminateUrl.split("<uid>")[0] + participant.updatedData.uid + participant.mappingData.terminateUrl.split("<uid>")[1];
        }else{
          url = participant.mappingData.terminateUrl;
        }
        window.open(url, '_self');
      }
    }

    return (
      <div>
        <img alt="" src={require("../../Image/background.jpg")} className="background-image" />
        <div className="success-logo">
          <img alt="" src={require("../../Image/panelreward-logo.png")} className="logo-image" />
        </div>
        <div className="success-text">
          <h2 style={{ fontSize: "2.5em", fontWeight: "bold", fontFamily: "'Merriweather', serif" }}>COMPLETED</h2>
          <p style={{ fontSize: "1.3em", fontWeight: "bold", lineHeight: "1.5" }}>
            Thank you for your time and effort to participate in our survey.
            Congratulations, you have successfully completed this survey.
            To view your rewards or update your profile please login.
                </p>
          <p style={{ fontSize: "1.3em", fontWeight: "bold" }}>
            We look forward to your participation in future surveys.
                </p>
          <p style={{ fontSize: "1.3em", fontWeight: "bold", lineHeight: "1.4" }}>
            Have a great day!<br />
            Panel Reward Team
                </p>
        </div>
      </div>
    );
  }
}

function getUrlParam(parameter, defaultvalue){
  var urlparameter = defaultvalue;
  if(window.location.href.indexOf(parameter) > -1){
      urlparameter = getUrlVars()[parameter];
      }
  return urlparameter;
}


function getUrlVars() {
  var vars = {};
  var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
      vars[key] = value;
  });
  return vars;
}

const mapStateToProps = (state) => {
  return {
    updateParticipantResp: state.successStore.updateParticipantResp,
    participantNewStudyUrlSuccess : state.successStore.participantNewStudyUrlSuccess,
    participantNewRedirectUrlData : state.successStore.participantNewRedirectUrlData
  }
}

export default connect(mapStateToProps)(StudySuccess);
